.layout {
  overflow: hidden;
  padding-top: 0;
  position: relative;
  width: 1000px;
}

.header {
  position: relative;
  zoom: 0.8;
}